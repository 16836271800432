@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --extra-brand-color: #d6001c;
  --extra-brand-highlight-color: #ff334f;
}

html,
body {
  height: 100%;
}

body {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 400;
  font-style: normal;
}

#__next {
  @apply flex;
  @apply flex-col;
  @apply w-screen;
  @apply h-screen;
  @apply overflow-hidden;
}

#__next .rdtPicker {
  padding: 0;
  border: none;
  box-shadow: none;
  border: 1px solid;
  @apply border-gray-900;
  @apply shadow-lg;
}

#__next .rdtNew,
#__next .rdtOld {
  @apply text-gray-800;
}

#__next .rdtActive {
  background-color: var(--extra-brand-highlight-color) !important;
}

@media only screen and (max-height: 840px) {
  .homepage-container { 
    align-items: flex-start;
  }
}